import "./App.css";
import { BrowserRouter, Routes, Route, Navigate, Link } from "react-router-dom";

import { useQuery, gql } from "@apollo/client";
import { Canada } from "./Canada";
import { Prov } from "./Prov";
import { Region } from "./Region";
import { Sources } from "./Sources";
import { PageFooter } from "./Chart";

const PROVINCES = gql`
  {
    pt {
      nameCanonical
      pop
      region
    }
  }
`;

function Menu() {
  const { loading, error, data } = useQuery(PROVINCES);

  if (loading)
    return (
      <div className="App">
        <header className="App-header">
          <p>Loading...</p>
        </header>
      </div>
    );
  else if (error) {
    console.log(error);
    return (
      <div className="App">
        <header className="App-header">
          <p>An Error Occured</p>
        </header>
      </div>
    );
  } else {
    console.log(data);
    return (
      <div className="App">
        <header className="App-header">
          <h2>
            <Link to="/Canada" className="App-link">
              Canada
            </Link>
          </h2>
          {data.pt.map((p, i) => (
            <p key={i}>
              <Link to={"/Prov/" + p.region} className="App-link">
                {p.nameCanonical} ({p.region})
              </Link>
              <br />
              <span className="Small">
                Population:{" "}
                {p.pop.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
              </span>
            </p>
          ))}
          <p>
            <Link className="App-link2" to="/Sources">
              Data Sources
            </Link>
          </p>

          <PageFooter />
        </header>
      </div>
    );
  }
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/Canada" element={<Canada />} />
        <Route path="/Sources" element={<Sources />} />
        <Route path="/Prov/:region" element={<Prov />} />
        <Route path="/Region/:region" element={<Region />} />
        <Route path="/" element={<Menu />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
