import "./App.css";
import React from "react";
import {
  PageHeader,
  LastDataTable,
  DaysBackMenu,
  ChartView,
  PageFooter,
} from "./Chart";
import { useLazyQuery, gql } from "@apollo/client";

import moment from "moment";

import colormap from "colormap";
import { useParams, Link } from "react-router-dom";

const QUERY = gql`
  query Query($region: String!, $dateAfter: String!) {
    name: pt(region: $region) {
      nameCanonical
      pop
    }
    regions: healthRegions(region: $region) {
      nameShort
      pop
      hruid
    }
    cases: casesPt(region: $region, dateAfter: $dateAfter) {
      date
      value
      valueDaily
    }
    deaths: deathsPt(region: $region, dateAfter: $dateAfter) {
      date
      value
      valueDaily
    }
    icu: icuPt(region: $region, dateAfter: $dateAfter) {
      date
      value
      valueDaily
    }
    hosp: hospitalizationsPt(region: $region, dateAfter: $dateAfter) {
      date
      value
      valueDaily
    }
    tests: testsCompletedPt(region: $region, dateAfter: $dateAfter) {
      date
      value
      valueDaily
    }
  }
`;

export function Prov() {
  const { region } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [daysBack, setDaysBack2] = React.useState(30);
  const [byRegion, setByRegion] = React.useState(false);

  const setDaysBack = (num) => {
    setLoading(true);
    setDaysBack2(num);
  };

  const [getData, { data }] = useLazyQuery(QUERY);

  React.useEffect(() => {
    getData({
      variables: {
        region: region,
        dateAfter: moment().subtract(daysBack, "days").format("YYYY-MM-DD"),
      },
      onCompleted: () => {
        console.log("loaded for days back: " + daysBack);
        console.log(moment().subtract(daysBack, "days").format("YYYY-MM-DD"));
        setLoading(false);
      },
      onError: (error) => {
        setError(error);
        setLoading(false);
      },
      //TODO - there must be a trigger for when results are cached -
      // but it isn't triggering onCompleted if cached, so need to do this fetchPolicy
      fetchPolicy: "cache-and-network",
    });
  }, [daysBack, getData, region]);

  const colors = colormap({ colormap: "cool", nshades: 9 });

  if (loading)
    return (
      <div className="App">
        <header className="App-header">
          <p>Loading...</p>
        </header>
      </div>
    );
  else if (error) {
    console.log(error);
    return (
      <div className="App">
        <header className="App-header">
          <p>An Error Occured</p>
        </header>
      </div>
    );
  } else {
    return (
      <div className="App">
        <header className="App-header">
          <PageHeader
            title={
              data.name.length
                ? data.name[0].nameCanonical + ` (${region})`
                : region
            }
            pop={data.name.length ? data.name[0].pop : null}
          />

          {/* Region List */}
          <button className="App-button" onClick={() => setByRegion(!byRegion)}>
            {byRegion ? "Hide" : "Show"} Regions
          </button>
          <br />
          {!!byRegion && (
            <table style={{ paddingBottom: "2em" }}>
              <thead>
                <tr>
                  <th>Region</th>
                  <th>Population</th>
                </tr>
              </thead>
              <tbody>
                {data.regions.map((r, i) => (
                  <tr key={i}>
                    <td>
                      <Link className="App-link" to={"/Region/" + r.hruid}>
                        {r.nameShort}
                      </Link>
                    </td>
                    <td>{r.pop.toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {/* Show the last data stats */}
          <LastDataTable data={data} />

          {/* Dropdown to change the dates */}
          <DaysBackMenu setDaysBack={setDaysBack} daysBack={daysBack} />

          {/* Show the graphs here! */}

          <ChartView data={data.cases} name="Daily Cases" color={colors[3]} />
          <ChartView data={data.deaths} name="Daily Deaths" color={colors[4]} />
          <ChartView
            data={data.icu}
            name="Daily Intensive Care (ICU)"
            color={colors[5]}
          />
          <ChartView
            data={data.hosp}
            name="Daily Hospitalizations"
            color={colors[6]}
          />
          <ChartView data={data.tests} name="Daily Tests" color={colors[7]} />

          <PageFooter />
        </header>
      </div>
    );
  }
}
