import "./App.css";
import React from "react";
import {
  PageHeader,
  LastDataTable,
  DaysBackMenu,
  ChartView,
  PageFooter,
} from "./Chart";
import { useLazyQuery, gql } from "@apollo/client";

import moment from "moment";

import colormap from "colormap";
import { useParams } from "react-router-dom";

const QUERY = gql`
  query Query($region: String!, $dateAfter: String!) {
    name: healthRegions(hruid: $region) {
      nameCanonical
      pop
    }
    cases: casesHr(subRegion1: $region, dateAfter: $dateAfter) {
      date
      value
      valueDaily
    }
    deaths: deathsHr(subRegion1: $region, dateAfter: $dateAfter) {
      date
      value
      valueDaily
    }
  }
`;

export function Region() {
  const { region } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [daysBack, setDaysBack2] = React.useState(30);

  const setDaysBack = (num) => {
    setLoading(true);
    setDaysBack2(num);
  };

  const [getData, { data }] = useLazyQuery(QUERY);

  React.useEffect(() => {
    getData({
      variables: {
        region: region,
        dateAfter: moment().subtract(daysBack, "days").format("YYYY-MM-DD"),
      },
      onCompleted: () => {
        console.log("loaded for days back: " + daysBack);
        console.log(moment().subtract(daysBack, "days").format("YYYY-MM-DD"));
        setLoading(false);
      },
      onError: (error) => {
        setError(error);
        setLoading(false);
      },
      //TODO - there must be a trigger for when results are cached -
      // but it isn't triggering onCompleted if cached, so need to do this fetchPolicy
      fetchPolicy: "cache-and-network",
    });
  }, [daysBack, getData, region]);

  const colors = colormap({ colormap: "cool", nshades: 9 });

  if (loading)
    return (
      <div className="App">
        <header className="App-header">
          <p>Loading...</p>
        </header>
      </div>
    );
  else if (error) {
    console.log(error);
    return (
      <div className="App">
        <header className="App-header">
          <p>An Error Occured</p>
        </header>
      </div>
    );
  } else {
    return (
      <div className="App">
        <header className="App-header">
          <PageHeader
            title={
              data.name.length
                ? data.name[0].nameCanonical + ` (${region})`
                : region
            }
            pop={data.name.length ? data.name[0].pop : null}
            isRegion
          />

          {/* Show the last data stats */}
          <LastDataTable data={data} isRegion/>

          {/* Dropdown to change the dates */}
          <DaysBackMenu setDaysBack={setDaysBack} daysBack={daysBack} />

          {/* Show the graphs here! */}

          <ChartView data={data.cases} name="Daily Cases" color={colors[3]} />
          <ChartView data={data.deaths} name="Daily Deaths" color={colors[4]} />

          <PageFooter />
        </header>
      </div>
    );
  }
}
