import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";

const SOURCES = gql`
  {
    dataSources {
      valueName
      region
      sourceName
    }
  }
`;

export function Sources() {
  const { loading, error, data } = useQuery(SOURCES);

  if (loading)
    return (
      <div className="App">
        <header className="App-header">
          <p>Loading...</p>
        </header>
      </div>
    );
  else if (error) {
    console.log(error);
    return (
      <div className="App">
        <header className="App-header">
          <p>An Error Occured</p>
        </header>
      </div>
    );
  } else {
    return (
      <div className="App">
        <header className="App-header">
          <h2>
            <Link to="/">&#127968;</Link> Sources
          </h2>
          <table>
            <thead>
              <tr>
                <th>Type</th>
                <th>Region</th>
                <th>Source</th>
              </tr>
            </thead>
            <tbody>
              {data.dataSources.map((d, i) => (
                <tr key={i}>
                  <td>{d.valueName}</td>
                  <td>{d.region}</td>
                  <td>{d.sourceName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </header>
      </div>
    );
  }
}
