import "./App.css";
import React from "react";
import {
  PageHeader,
  LastDataTable,
  DaysBackMenu,
  ChartView,
  PageFooter,
} from "./Chart";
import { useLazyQuery, gql } from "@apollo/client";

import moment from "moment";

import colormap from "colormap";

const QUERY = gql`
  query Query($dateAfter: String!) {
    cases: casesCan(dateAfter: $dateAfter) {
      date
      value
      valueDaily
    }
    deaths: deathsCan(dateAfter: $dateAfter) {
      date
      value
      valueDaily
    }
    icu: icuCan(dateAfter: $dateAfter) {
      date
      value
      valueDaily
    }
    hosp: hospitalizationsCan(dateAfter: $dateAfter) {
      date
      value
      valueDaily
    }
    tests: testsCompletedCan(dateAfter: $dateAfter) {
      date
      value
      valueDaily
    }
  }
`;

export function Canada() {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [daysBack, setDaysBack2] = React.useState(30);

  const setDaysBack = (num) => {
    setLoading(true);
    setDaysBack2(num);
  };

  const [getData, { data }] = useLazyQuery(QUERY);

  React.useEffect(() => {
    getData({
      variables: {
        dateAfter: moment().subtract(daysBack, "days").format("YYYY-MM-DD"),
      },
      onCompleted: () => {
        console.log("loaded for days back: " + daysBack);
        console.log(moment().subtract(daysBack, "days").format("YYYY-MM-DD"));
        setLoading(false);
      },
      onError: (error) => {
        setError(error);
        setLoading(false);
      },
      //TODO - there must be a trigger for when results are cached -
      // but it isn't triggering onCompleted if cached, so need to do this fetchPolicy
      fetchPolicy: "cache-and-network",
    });
  }, [daysBack, getData]);

  const colors = colormap({ colormap: "cool", nshades: 9 });

  if (loading)
    return (
      <div className="App">
        <header className="App-header">
          <p>Loading...</p>
        </header>
      </div>
    );
  else if (error) {
    console.log(error);
    return (
      <div className="App">
        <header className="App-header">
          <p>An Error Occured</p>
        </header>
      </div>
    );
  } else {
    return (
      <div className="App">
        <header className="App-header">
          <PageHeader title="Canada" />

          {/* Show the last data stats */}
          <LastDataTable data={data} />

          {/* Dropdown to change the dates */}
          <DaysBackMenu setDaysBack={setDaysBack} daysBack={daysBack} />

          {/* Show the graphs here! */}

          <ChartView data={data.cases} name="Daily Cases" color={colors[3]} />
          <ChartView data={data.deaths} name="Daily Deaths" color={colors[4]} />
          <ChartView
            data={data.icu}
            name="Daily Intensive Care (ICU)"
            color={colors[5]}
          />
          <ChartView
            data={data.hosp}
            name="Daily Hospitalizations"
            color={colors[6]}
          />
          <ChartView data={data.tests} name="Daily Tests" color={colors[7]} />

          <PageFooter />
        </header>
      </div>
    );
  }
}
