import "./App.css";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Chart from "react-apexcharts";

export function LastDataTable({ data, isRegion }) {
  return (
    <table>
      <thead>
        <tr>
          <th></th>
          <th>Daily</th>
          <th>Total</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {!!data.cases.length && (
          <tr>
            <td>Cases:</td>
            <td>{data.cases.slice(-1)[0].valueDaily.toLocaleString()}</td>
            <td>{data.cases.slice(-1)[0].value.toLocaleString()}</td>
            <td className="Small">({data.cases.slice(-1)[0].date})</td>
          </tr>
        )}
        {!!data.deaths.length && (
          <tr>
            <td>Deaths:</td>
            <td>{data.deaths.slice(-1)[0].valueDaily.toLocaleString()}</td>
            <td>{data.deaths.slice(-1)[0].value.toLocaleString()}</td>
            <td className="Small">({data.deaths.slice(-1)[0].date})</td>
          </tr>
        )}
        {!isRegion && (
          <>
            {!!data.icu.length && (
              <tr>
                <td>ICU:</td>
                <td>{data.icu.slice(-1)[0].valueDaily.toLocaleString()}</td>
                <td>{data.icu.slice(-1)[0].value.toLocaleString()}</td>
                <td className="Small">({data.icu.slice(-1)[0].date})</td>
              </tr>
            )}
            {!!data.hosp.length && (
              <tr>
                <td>In Hospital:</td>
                <td>{data.hosp.slice(-1)[0].valueDaily.toLocaleString()}</td>
                <td>{data.hosp.slice(-1)[0].value.toLocaleString()}</td>
                <td className="Small">({data.hosp.slice(-1)[0].date})</td>
              </tr>
            )}
            {!!data.tests.length && (
              <tr>
                <td>Tests:</td>
                <td>{data.tests.slice(-1)[0].valueDaily.toLocaleString()}</td>
                <td>{data.tests.slice(-1)[0].value.toLocaleString()}</td>
                <td className="Small">({data.tests.slice(-1)[0].date})</td>
              </tr>
            )}
          </>
        )}
      </tbody>
    </table>
  );
}

export function DaysBackMenu({ setDaysBack, daysBack }) {
  return (
    <label>
      <br />
      <span>Date Range: </span>
      <select
        name="daysBack"
        onChange={(e) => setDaysBack(e.target.value)}
        value={daysBack}
      >
        <option value={32}>last 30 days</option>
        <option value={62}>last 60 days</option>
        <option value={94}>last quarter</option>
        <option value={185}>last 6 months</option>
        <option value={367}>last year</option>
        <option value={740}>last 2 years</option>
        <option value={9999}>All Time</option>
      </select>
    </label>
  );
}

export function PageHeader({ title, pop, isRegion }) {
  const navigate = useNavigate();

  return (
    <>
      <h2>
        <Link to="/" className="App-link2">
          &#x1F3E0;{" "}
        </Link>
        {!!isRegion && (
          <button className="App-button-link" onClick={() => navigate(-1)}>
            &#128281;
          </button>
        )}
        {title}
        <br />
        {pop ? (
          <span className="Small">Population: {pop.toLocaleString()}</span>
        ) : null}
      </h2>
    </>
  );
}

export function PageFooter() {
  return (
    <>
      <p>
        <Link to="/" className="App-link2">
          https://covidpy.mohanjit.com
        </Link>
      </p>
      <p>
        <a href="https://mohanjit.com" className="App-link2">
          https://mohanjit.com
        </a>
      </p>
    </>
  );
}

export function ChartView({ data, type, name, color }) {
  return (
    <Chart
      style={{
        paddingTop: "2em",
        width: "90%",
      }}
      type="line"
      options={{
        theme: { mode: "dark" },
        stroke: {
          curve: "smooth",
          colors: color,
        },
        chart: {
          id: name,
        },
        xaxis: {
          categories: data.map((r) => r.date),
          labels: {
            style: {
              colors: "ghostwhite",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "ghostwhite",
            },
          },
        },
        title: {
          text: name,
          style: {
            color: "ghostwhite",
          },
        },
        tooltip: {
          theme: "dark",
        },
      }}
      series={[
        {
          name: name,
          data: data.map((r) => r.valueDaily),
        },
      ]}
    />
  );
}
